<template>
  <component
    :is="$store.state.business.unsaved.type"
    v-if="$store.state.business.unsaved.type"
  />
</template>
<script>
export default {
  components: {
    datepicker() {
      return import("./datepicker");
    },
    services() {
      return import("./services");
    },
    eventpicker() {
      return import("./eventpicker");
    }
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="sass" scoped></style>
